import type { PaginateResult } from "mongoose"

export enum BudgetIntervalSource {
  ASSET_COMPONENT = "ASSET_COMPONENT",
  BUDGET_SETTING_CATEGORY = "BUDGET_SETTING_CATEGORY",
  BUDGET_SETTING = "BUDGET_SETTING",
  DEFAULT_BUDGET_SETTING = "DEFAULT_BUDGET_SETTING",
  DEFAULT = "DEFAULT",
}

export enum BudgetPriceSource {
  ASSET_COMPONENT = "ASSET_COMPONENT",
  ASSET_VALUE = "ASSET_VALUE",
}

export interface YearsMap {
  [year: string]: number | undefined
}

export interface Budget {
  _id: string

  organization: string
  asset: string

  /** `AssetComponentGroup["componentGroup"]` */
  groupId: string

  /** `AssetComponentGroup["_id"]` */
  assetGroupId: string

  /** `AssetComponent["component"]` */
  componentId: string

  /** `AssetComponent["_id"]` */
  assetComponentId: string

  /** `AssetComponentGroup["description"]` */
  group: string

  /** `AssetComponent["description"]` */
  component: string

  /**
   * The interval is calculated as follows:
   * 1. `AssetComponent["budget"]["interval"]`
   * 2. `BudgetSetting["categories"]` > `BudgetCategorySetting["components"]` > `BudgetComponentIntervalSetting["interval"]`
   * 3. `BudgetSetting["components"]` > `BudgetComponentIntervalSetting["interval"]`
   * 4. `BudgetSetting["components"]` > `BudgetComponentIntervalSetting["interval"]` (SAM default interval)
   * 5. `BudgetSetting["defaultInterval"]` (SAM default interval)
   * */
  interval: number

  /**
   * The interval source is calculated as follows:
   * 1. ASSET_COMPONENT: `AssetComponent["budget"]["interval"]`
   * 2. BUDGET_SETTING_CATEGORY: `BudgetSetting["categories"]` > `BudgetCategorySetting["components"]` > `BudgetComponentIntervalSetting["interval"]`
   * 3. BUDGET_SETTING: `BudgetSetting["components"]` > `BudgetComponentIntervalSetting["interval"]`
   * 4. DEFAULT: `BudgetSetting["components"]` > `BudgetComponentIntervalSetting["interval"]` (SAM default interval)
   * 4. DEFAULT: `BudgetSetting["defaultInterval"]` (SAM default interval)
   */
  intervalSource: BudgetIntervalSource

  /**
   * `BudgetSetting["correction"][CONDITION]`
   * - [ >= 0 ]: `startYear + correctionYears > buildYear + interval`, use last year.
   * - [  < 0 ]: `startYear + correctionYears < buildYear + interval`, use first year.
   */
  correctionYears: number

  /** `AssetComponent["condition"]["rating"]` */
  conditionRating: string

  /** `AssetComponent["condition"]["inspection"]` */
  conditionSource?: string

  /** `AssetComponent["condition"]["date"]` */
  conditionDate?: Date

  /**
   * The index is calculated as follows:
   * 1. `BudgetSetting["indexPercentage"]`
   * 2. `BudgetSetting["indexPercentage"]` (SAM default index percentage)
   */
  indexPercentage: number

  /** Indexed prices per year, based on `price`, `priceYear` and `indexPercentage`. */
  yearPrices: YearsMap

  /**
   * The price is calculated as follows:
   * 1. `AssetComponent["budget"]["price"]`
   * 2. `AssetComponent["values"]` > `AssetValue["lookupValue"]` > `LookupValue["price"]`
   */
  price?: number

  /**
   * The priceYear is calculated as follows:
   * 1. `AssetComponent["budget"]["priceYear"]`
   * 2. `AssetComponent["values"]` > `AssetValue["lookupValue"]` > `LookupValue["priceYear"]`
   */
  priceYear?: number

  /**
   * The priceSource is calculated as follows:
   * 1. ASSET_COMPONENT: `AssetComponent["budget"]["price"]`
   * 2. ASSET_VALUE: `AssetComponent["values"]` > `AssetValue["lookupValue"]` > `LookupValue["price"]`
   */
  priceSource?: BudgetPriceSource

  /**
   * The buildYear is calculated as follows:
   * 1. Find buildYear property: `AssetComponent["properties"]` > `AssetProperty["valueType"]` === `PropertyValueType.BUILD_YEAR`
   * 2. Find buildYear value: `AssetComponent["values"]` > `AssetValue["componentProperty"]` === `ComponentProperty["_id"]`
   */
  buildYear?: number
}

export const useBudgetStore = defineStore("budget", () => {
  /** @deprecated */
  const budgets = ref<Array<Budget>>([])

  const getBudgets = async (queryParameters?: Record<string, unknown>) => useCrudMethods<Budget>("/budgets", budgets).readItems(queryParameters)

  const getBudgetsByPage = async (queryParameters?: Record<string, unknown>) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Budget>>(`/budgets?${query}`)
    return data
  }

  const getBudgetById = async (id: string) => useCrudMethods<Budget>("/budgets", budgets).readItemById(id)

  const generateBudgetsForOrganization = async (organizationId: string) => {
    const { data } = await useSamApi<Array<Budget>>(`/budgets/generate/${organizationId}`, { method: "POST" })

    budgets.value = budgets.value.filter((budget) => budget.organization !== organizationId).concat(data)

    return data
  }

  const generateBudgetsForAsset = async (organizationId: string, assetId: string) => {
    const { data } = await useSamApi<Array<Budget>>(`/budgets/generate/${organizationId}/${assetId}`, { method: "POST" })

    budgets.value = budgets.value.filter((budget) => budget.asset !== assetId).concat(data)

    return data
  }

  const generateAllBudgets = async () => {
    await useSamApi(`/budgets/generate`, { method: "POST" })
  }

  const getBudgetsForOrganization = async (organizationId: string) => {
    const { data } = await useSamApi<Array<Budget>>(`/budgets/organization/${organizationId}`)
    budgets.value = data

    return data
  }

  const getBudgetsForAsset = async (assetId: string) => {
    const { data } = await useSamApi<Array<Budget>>(`/budgets/asset/${assetId}`)

    return data
  }

  return {
    budgets,
    getBudgets,
    getBudgetsByPage,
    getBudgetById,

    generateBudgetsForOrganization,
    generateBudgetsForAsset,
    generateAllBudgets,

    getBudgetsForOrganization,
    getBudgetsForAsset,
  }
})
